<template>
  <div class="header mb-5">
    <v-app-bar
      :app="$store.state.break_point <= 991"
      color="white"
      flat
      height="65"
    >
      <v-container class="py-0 align-center d-flex justify-space-between">
        <v-btn
          v-if="$store.getters.userType != 'driver'"
          outlined
          color="primary"
          target="_blank"
          :href="`https://kal.chekly.com/${$store.state.election.uuid}`"
        >
          דף ציבורי
        </v-btn>
        <v-btn
          v-if="
            $store.state.break_point <= 991 &&
            $store.getters.userType == 'admin'
          "
          small
          icon
          @click="$store.state.drawer = !$store.state.drawer"
        >
          <v-icon>menu</v-icon>
        </v-btn>
        <v-btn
          @click="logout"
          v-if="$store.getters.userType != 'admin'"
          outlined
        >
          להתנתק</v-btn
        >
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data: () => ({}),
  methods: {
    logout() {
      let vm = this;
      this.$store.commit("logout");
      this.$nextTick(() => {
        vm.$router.push({ name: "login" });
      });
    },
  },
};
</script>
