import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import AppAside from "@/layout/AppAside.vue";
Vue.use(VueRouter);
Vue.component("AppAside", AppAside);
import notfound from "@/views/not_found.vue";
const login = () => import("@/views/Login.vue");

const votersD = () => import("@/views/Drivers/votersD");
const vote = () => import("@/views/Employees/vote");
const drivers = () => import("@/views/drivers");
const employees = () => import("@/views/employees");
const driver = () => import("@/views/driver");
const places = () => import("@/views/places");
const families = () => import("@/views/families");
const statuses = () => import("@/views/statuses");
const voters = () => import("@/views/voters");
const boxes = () => import("@/views/boxes");
const problem = () => import("@/views/problem");
const settings = () => import("@/views/settings");
const dashboard = () => import("@/views/dashboard");

const routes = [
  {
    path: "/vote",
    name: "vote",
    component: vote,
    meta: {
      title: "vote",
      requiresAuth: true,
      type: "employee",
    },
  },
  {
    path: "/votersD",
    name: "votersD",
    component: votersD,
    meta: {
      title: "votersD",
      requiresAuth: true,
      type: "driver",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
    meta: {
      title: "dashboard",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/employees",
    name: "employees",
    component: employees,
    meta: {
      title: "employees",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: settings,
    meta: {
      title: "settings",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/voters",
    name: "voters",
    component: voters,
    meta: {
      title: "voters",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/statuses",
    name: "statuses",
    component: statuses,
    meta: {
      title: "statuses",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/drivers",
    name: "drivers",
    component: drivers,
    meta: {
      title: "drivers",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/driver/:id",
    name: "driver",
    component: driver,
    meta: {
      title: "driver",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/families",
    name: "families",
    component: families,
    meta: {
      title: "families",
      requiresAuth: true,
      type: "admin",
    },
  },

  {
    path: "/boxes",
    name: "boxes",
    component: boxes,
    meta: {
      title: "boxes",
      requiresAuth: true,
      type: "admin",
    },
  },
  {
    path: "/places",
    name: "places",
    component: places,
    meta: {
      title: "places",
      requiresAuth: true,
      type: "admin",
    },
  },
  //--------------- public
  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      title: "login",
      requiresAuth: false,
    },
  },

  {
    path: "/problem",
    name: "problem",
    component: problem,
    meta: {
      title: "problem",
      requiresAuth: false,
    },
  },
  {
    path: "*",
    name: "notfound",
    component: notfound,
    meta: {
      title: "aaa",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next();
    } else {
      if (store.getters.userType == "admin") {
        next("/voters");
      } else if (store.getters.userType == "driver") {
        next("/votersD");
      }
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next({
        name: "login",
      });
    } else {
      if (store.getters.userType == to.meta.type) {
        next();
      } else {
        if (store.getters.userType == "admin") {
          next("/voters");
        } else if (store.getters.userType == "driver") {
          next("/votersD");
        }
      }
    }
  }
});
