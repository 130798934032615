<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow text-center">
        <img src="@/assets/error.png" />
        <v-btn x-large @click="back()" color="primary" outlined>חזור</v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "notfound",
  methods: {
    back() {
      this.$router.push("/voters");
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width:100%;
  display: block;
  margin: 40px auto;
}
</style>
